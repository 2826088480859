import React, { useEffect, useState } from 'react';
import './shared-components/css/carousel.css'; // Create a CSS file for styling
import './shared-components/css/home.css';
import moment from 'moment'; // Import moment library for date manipulation
// Images
import sidebar from '../../../assets/images/photos/sidebar.jpg'
import squatbar from '../../../assets/images/photos/squatwithbar.jpg'
import pushup from '../../../assets/images/photos/pushup.jpg'
import kbs from '../../../assets/images/photos/kbs.jpg'
import kbschest from '../../../assets/images/photos/kbchest.jpg'
import meettheboss from '../../../assets/images/photos/meettheboss.jpg'
import squatting from '../../../assets/images/photos/squatting.jpg';
import flexing from '../../../assets/images/photos/flexing.jpg';
import jumping from '../../../assets/images/photos/jumping.jpg';
import smiling from '../../../assets/images/photos/smiling.jpg';
import flaco from '../../../assets/images/photos/flaco.jpg';
import highandsmiling from '../../../assets/images/photos/highandsmiling.jpg';
// Components
import DropIn from './shared-components/Button-DropIn';
import Subscriptions from './shared-components/Button-Monthly';
import OverlayMessage from './shared-components/OverlayMessage';
import LandingContent from './shared-components/LandingMessage';
import Social from './shared-components/Social';
import Footer from '../../shared-components/footer/Footer';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../atoms/Atoms';
import bookingApi from '../../../api/bookingApi';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  fadeOutMotion,
  fadeInMotion
} from '../../../functions';


function Home() {
  const user = useRecoilValue(userAtom);
  const [showRedBanner, setShowRedBanner] = useState(false);
   const [isVisible, setIsVisible] = useState(false);


  function getUserSubscriptions() {
    bookingApi
      .get(`subscriptions/${user?.id}`)
      .then((response) => {
        checkSubscriptions(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const checkSubscriptions = (sub) => {
    const today = moment();
    const oneWeekAgo = moment().subtract(7, 'days');

    const hasExpiredSubscription = sub.some((subscription) => {
      const endDate = moment(subscription.endDate).add(-4, 'hour');
      return endDate.isBetween(oneWeekAgo, today, null, '[]');
    });

    setShowRedBanner(hasExpiredSubscription);
  };

  useEffect(() => {
    if (user) {
      getUserSubscriptions();
    }
  }, [user]);

      const images = [
        pushup,
        squatting,
        jumping,
        flaco,
        highandsmiling,
        meettheboss
      ];


    const [currentIndex, setCurrentIndex] = useState(0);
    const [cycles, setCycles] = useState(-1);
    const maxCycles = 1; // Set the maximum number of cycles
    
    // No limit of cycles
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //     // Cycle through images
    //     setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    //     }, 2000); // Change image every X seconds
    
    //     return () => {
    //     clearInterval(interval);
    //     };
    // }, []);


    // Limited Cycles
    let interval; // Declare the interval variable here

    useEffect(() => {
      interval = setInterval(() => {
        if (currentIndex === images.length-2) {
            setCycles((prevCycles) => prevCycles + 1);
          }
          setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 1500); // Change image every 2 seconds
      return () => {
        clearInterval(interval); // Clear the interval in the cleanup function
      };
    }, [currentIndex]);
  
    useEffect(() => {
      if (cycles >= maxCycles) {
        clearInterval(interval); // Clear the interval when the desired number of cycles is reached
      }
    }, [cycles]);

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 20000);
  
      return () => clearTimeout(timer);
    }, []);
     

    return (
      <div>
        {/* {showRedBanner && (
          <Link to="/profile/section/subscriptions" className="red-banner-link">
            <div className="red-banner">
              Your subscription is expired. Click here to renew.
            </div>
          </Link>
        )} */}
         {isVisible && (
        <motion.div
          initial={fadeOutMotion}
          animate={fadeInMotion}
          exit={fadeOutMotion}
        >
          <div className="orange-banner">
            We will be opening the doors to our new facility located at Meca Complex in Cupey on March 4th, 2024.
          <Link to="/subscriptions" className="banner-link">
          <div>
          <p className="text-white underline">Check out available subscriptions here</p>
        </div>
          {/* <div className="orange-banner">
            <p className="font-semibold tracking-tighter text-gray-700 px-2">We will be opening the doors to our new facility located at Meca Complex this week for interested clients to learn about our methodology.</p>
          <Link to="/events/Kb" className="banner-link">
          <div>
          <p className="text-white underline">Book a space for free here</p>
        </div> */}
      </Link>
      </div>
        </motion.div>
      )}
       
        <div className="body">
          <div className="main-bkg" />
          <div className="carousel">
            {images.map((image, index) => (
              <div
                key={image}
                className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
                style={{ backgroundImage: `url(${image})` }}
              />
            ))}
          </div>
        {/* <Social className="social" /> */}
          <OverlayMessage />
          <LandingContent />
          <Footer />
          <div key="optionsDiv" className="optionsButton">
            <DropIn />
            <Subscriptions />
          </div>
        </div>
      </div>
    );
  }

export default Home;
